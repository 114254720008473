.gj-button {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #000;
  border-radius: 3px;
  padding: 6px 10px;
  cursor: pointer;
}

.gj-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.gj-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.gj-margin-left-5 {
  margin-left: 5px;
}

.gj-margin-left-10 {
  margin-left: 10px;
}

.gj-width-full {
  width: 100%;
}

.gj-cursor-pointer {
  cursor: pointer;
}

.gj-text-align-center {
  text-align: center;
}

.gj-font-size-16 {
  font-size: 16px;
}

.gj-hidden {
  display: none;
}

/** Material Design */
.gj-button-md {
  background: 0 0;
  border: none;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.87);
  position: relative;
  height: 36px;
  margin: 0;
  min-width: 64px;
  padding: 0 16px;
  display: inline-block;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow;
  transition: box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1);
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.gj-button-md:hover {
  background-color: rgba(158,158,158,.2);
}

.gj-button-md:disabled {
  color: rgba(0,0,0,.26);
  background: 0 0;
}

.gj-button-md .material-icons,
.gj-button-md .gj-icon {
  vertical-align: middle;
  /*font-size: 1.3rem;
  margin-right: 4px;*/
}

.gj-button-md.gj-button-md-icon {
  width: 24px;
  height: 31px;
  min-width: 24px;
  padding: 0px;
  display: table;
}

.gj-button-md.gj-button-md-icon .material-icons,
.gj-button-md.gj-button-md-icon .gj-icon {
  display: table-cell;
  margin-right: 0px;
  width: 24px;
  height: 24px;
}

.gj-button-md.active {
  background-color: rgba(158,158,158,.4);
}

.gj-button-md-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.gj-textbox-md {
  border: none;
  border-bottom: 1px solid rgba(0,0,0,.42);
  display: block;
  font-family: "Helvetica","Arial",sans-serif;
  font-size: 16px;
  line-height: 16px;
  padding: 4px 0px;
  margin: 0;
  width: 100%;
  background: 0 0;
  text-align: left;
  color: rgba(0,0,0,.87);
}

.gj-textbox-md:focus,
.gj-textbox-md:active {
  border-bottom: 2px solid rgba(0,0,0,.42);
  outline: none;
}

.gj-textbox-md::placeholder {
  color: #8e8e8e;
}

.gj-textbox-md:-ms-input-placeholder {
  color: #8e8e8e;
}

.gj-textbox-md::-ms-input-placeholder {
  color: #8e8e8e;
}

.gj-md-spacer-24 {
  min-width: 24px;
  width: 24px;
  display: inline-block;
}

.gj-md-spacer-32 {
  min-width: 32px;
  width: 32px;
  display: inline-block;
}

.gj-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1203;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.54118);
  transition: 200ms ease opacity;
  will-change: opacity;
}

/* List */
ul.gj-list li [data-role="wrapper"] {
  display: table;
  width: 100%;
}

ul.gj-list li [data-role="checkbox"] {
  display: table-cell;
  vertical-align:middle;
  text-align:center;
}

ul.gj-list li [data-role="image"] {
  display: table-cell;
  vertical-align:middle;
  text-align:center;
}

ul.gj-list li [data-role="display"] {
  display: table-cell;
  vertical-align:middle;
  cursor: pointer;
}

ul.gj-list li [data-role="display"]:empty:before {
  content: "\200b"; /* unicode zero width space character */
}

/* List - Bootstrap */
ul.gj-list-bootstrap {
  padding-left: 0px;
  margin-bottom: 0px;
}

ul.gj-list-bootstrap li {
  padding: 0px;
}

ul.gj-list-bootstrap li [data-role="wrapper"] {
  padding: 0px 10px;
}

ul.gj-list-bootstrap li [data-role="checkbox"] {
  width: 24px;
  padding: 3px;
}

ul.gj-list-bootstrap li [data-role="image"] {
  width: 24px;
  height: 24px;
}

ul.gj-list-bootstrap li [data-role="display"] {
  padding: 8px 0px 8px 4px;
}

.list-group-item.active ul li, .list-group-item.active:focus ul li, .list-group-item.active:hover ul li {
  text-shadow: none;
  color:initial;
}

/* List - Material Design */
ul.gj-list-md {
  padding: 0px;
  list-style: none;
  list-style-type: none;
  line-height: 24px;
  letter-spacing: 0;
  color: #616161; /* Gray 700 */
}

ul.gj-list-md li {
  display: list-item;
  list-style-type: none;
  padding: 0px;
  min-height: unset;
  box-sizing: border-box;
  align-items: center;
  cursor: default;
  overflow: hidden;

  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .04em;
  line-height: 1;

  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

ul.gj-list-md li [data-role="checkbox"] {
  height: 24px;
  width: 24px;
}

ul.gj-list-md li [data-role="image"] {
  height: 24px;
  width: 24px;
}

ul.gj-list-md li [data-role="display"] {
  padding: 8px 0px 8px 5px;
  order: 0;
  flex-grow: 2;
  text-decoration: none;
  box-sizing: border-box;
  align-items: center;
  text-align: left;
  color: rgba(0,0,0,.87);
}

ul.gj-list-md li.disabled>[data-role="wrapper"]>[data-role="display"] {
  color: #9E9E9E; /* Gray 500 */
}

.gj-list-md-active {
  background: #e0e0e0;
  color: #3f51b5;
}


/* Picker */
.gj-picker {
  position: absolute;
  z-index: 1203;
  background-color: #fff;
}

.gj-picker .selected {
  color: #fff;
}

/* Material Design */
.gj-picker-md {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .04em;
  line-height: 1;
  color: rgba(0,0,0,.87);
  border: 1px solid #E0E0E0;
}

.gj-modal .gj-picker-md {
  border: 0px;
}

.gj-picker-md [role="header"] {
  color: rgba(255, 255, 255, 0.54);
  display: flex;
  background: #2196f3;
  align-items: baseline;
  user-select: none;
  justify-content: center;
}

.gj-picker-md [role="footer"] {
  float: right;
  padding: 10px;
}

.gj-picker-md [role="footer"] button.gj-button-md {
  color: #2196f3;
  font-weight: bold;
  font-size: 13px;
}

/* Bootstrap */
.gj-picker-bootstrap {
  border-radius: 4px;
  border: 1px solid #E0E0E0;
}

.gj-picker-bootstrap .selected {
  color: #888;
}

.gj-picker-bootstrap [role="header"] {
  background: #eee;
  color: #AAA;
}
@font-face {
  font-family: 'gijgo-material';
  src: url('../fonts/gijgo-material.eot?235541');
  src: url('../fonts/gijgo-material.eot?235541#iefix') format('embedded-opentype'), url('../fonts/gijgo-material.ttf?235541') format('truetype'), url('../fonts/gijgo-material.woff?235541') format('woff'), url('../fonts/gijgo-material.svg?235541#gijgo-material') format('svg');
  font-weight: normal;
  font-style: normal;
}

.gj-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'gijgo-material' !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gj-icon.undo:before {
  content: "\e900";
}

.gj-icon.vertical-align-top:before {
  content: "\e901";
}

.gj-icon.vertical-align-center:before {
  content: "\e902";
}

.gj-icon.vertical-align-bottom:before {
  content: "\e903";
}

.gj-icon.arrow-dropup:before {
  content: "\e904";
}

.gj-icon.clock:before {
  content: "\e905";
}

.gj-icon.refresh:before {
  content: "\e906";
}

.gj-icon.last-page:before {
  content: "\e907";
}

.gj-icon.first-page:before {
  content: "\e908";
}

.gj-icon.cancel:before {
  content: "\e909";
}

.gj-icon.clear:before {
  content: "\e90a";
}

.gj-icon.check-circle:before {
  content: "\e90b";
}

.gj-icon.delete:before {
  content: "\e90c";
}

.gj-icon.arrow-upward:before {
  content: "\e90d";
}

.gj-icon.arrow-forward:before {
  content: "\e90e";
}

.gj-icon.arrow-downward:before {
  content: "\e90f";
}

.gj-icon.arrow-back:before {
  content: "\e910";
}

.gj-icon.list-numbered:before {
  content: "\e911";
}

.gj-icon.list-bulleted:before {
  content: "\e912";
}

.gj-icon.indent-increase:before {
  content: "\e913";
}

.gj-icon.indent-decrease:before {
  content: "\e914";
}

.gj-icon.redo:before {
  content: "\e915";
}

.gj-icon.align-right:before {
  content: "\e916";
}

.gj-icon.align-left:before {
  content: "\e917";
}

.gj-icon.align-justify:before {
  content: "\e918";
}

.gj-icon.align-center:before {
  content: "\e919";
}

.gj-icon.strikethrough:before {
  content: "\e91a";
}

.gj-icon.italic:before {
  content: "\e91b";
}

.gj-icon.underlined:before {
  content: "\e91c";
}

.gj-icon.bold:before {
  content: "\e91d";
}

.gj-icon.arrow-dropdown:before {
  content: "\e91e";
}

.gj-icon.done:before {
  content: "\e91f";
}

.gj-icon.pencil:before {
  content: "\e920";
}

.gj-icon.minus:before {
  content: "\e921";
}

.gj-icon.plus:before {
  content: "\e922";
}

.gj-icon.chevron-up:before {
  content: "\e923";
}

.gj-icon.chevron-right:before {
  content: "\e924";
}

.gj-icon.chevron-down:before {
  content: "\e925";
}

.gj-icon.chevron-left:before {
  content: "\e926";
}

.gj-icon.event:before {
  content: "\e927";
}

ul.gj-list li [data-role="spacer"] {
  display: table-cell;
}

ul.gj-list li [data-role="expander"] {
  display: table-cell;
  vertical-align:middle;
  text-align:center;
  cursor: pointer;
}

[data-type="tree"] ul li [data-role="expander"].gj-tree-material-icons-expander {
  width: 24px;
}

[data-type="tree"] ul li [data-role="expander"].gj-tree-font-awesome-expander {
  width: 24px;
}

[data-type="tree"] ul li [data-role="expander"].gj-tree-glyphicons-expander {
  width: 24px;
}

[data-type="tree"] ul li [data-role="expander"].gj-tree-glyphicons-expander .glyphicon {
  top: 4px;
  height: 24px;
}

/* Bootstrap Theme */
.gj-tree-bootstrap-3 ul.gj-list-bootstrap li {
  border: 0px;
  border-radius: 0px;
  color: #333;
}

.gj-tree-bootstrap-3 ul.gj-list-bootstrap li.active {
  color: #fff;
}

.gj-tree-bootstrap-3 ul.gj-list-bootstrap li.disabled {
  color: #777;
  background-color: #eee;
}

.gj-tree-bootstrap-4 ul.gj-list-bootstrap li {
  border: 0px;
  border-radius: 0px;
  color: #212529;
}

.gj-tree-bootstrap-4 ul.gj-list-bootstrap li.active {
  color: #fff;
}

.gj-tree-bootstrap-4 ul.gj-list-bootstrap li.disabled {
  color: #868e96;
}

.gj-tree-bootstrap-4 ul.gj-list-bootstrap li ul.gj-list-bootstrap {
  width: 100%;
}

.gj-tree-bootstrap-border ul.gj-list-bootstrap li {
  border: 1px solid #ddd;
}

.gj-tree-bootstrap-border ul.gj-list-bootstrap li ul.gj-list-bootstrap li {
  border-left: 0px;
  border-right: 0px;
}

.gj-tree-bootstrap-border ul.gj-list-bootstrap li:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.gj-tree-bootstrap-border ul.gj-list-bootstrap li:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.gj-tree-bootstrap-border ul.gj-list-bootstrap li ul.gj-list-bootstrap li:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.gj-tree-bootstrap-border ul.gj-list-bootstrap li ul.gj-list-bootstrap li:last-child {
  border-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

ul.gj-list-bootstrap li [data-role="expander"].gj-tree-material-icons-expander {
  padding-top: 8px;
  padding-bottom: 4px;
}

ul.gj-list-bootstrap li [data-role="expander"].gj-tree-material-icons-expander .gj-icon {
  width: 24px;
  height: 24px;
}

/* Material Design Theme */
ul.gj-list-md li.disabled > [data-role="wrapper"] > [data-role="expander"] {
  color: #9E9E9E; /* Gray 500 */
}

.gj-tree-md-border ul.gj-list-md li {
  border: 1px solid #616161; /* Gray 700 */
  margin-bottom: -1px;
}

.gj-tree-md-border ul.gj-list-md li ul.gj-list-md li {
  border-left: 0px;
  border-right: 0px;
}

.gj-tree-md-border ul.gj-list-md li ul.gj-list-md li:last-child {
  border-bottom: 0px;
}
.gj-tree-drop-above {
  border-top: 1px solid #000;
}

.gj-tree-drop-below {
  border-bottom: 1px solid #000;
}

.gj-tree-bootstrap-3 ul.gj-list-bootstrap li [data-role="wrapper"].drop-above {
  border-top: 2px solid #000;
}

.gj-tree-bootstrap-3 ul.gj-list-bootstrap li [data-role="wrapper"].drop-below {
  border-bottom: 2px solid #000;
}

.gj-tree-bootstrap-4 ul.gj-list-bootstrap li [data-role="wrapper"].drop-above {
  border-top: 2px solid #000;
}

.gj-tree-bootstrap-4 ul.gj-list-bootstrap li [data-role="wrapper"].drop-below {
  border-bottom: 2px solid #000;
}

.gj-tree-drag-el {
  padding: 0px;
  margin: 0px;
  z-index: 1203;
}

.gj-tree-drag-el li {
  padding: 0px;
  margin: 0px;
}

.gj-tree-drag-el [data-role="wrapper"] {
  cursor: move;
  display: table;
}

.gj-tree-drag-el [data-role="indicator"] {
  width: 14px;
  padding: 0px 3px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.gj-tree-bootstrap-drag-el li.list-group-item {
  border: 0px;
  background: unset;
}

.gj-tree-bootstrap-drag-el [data-role="indicator"] {
  width: 24px;
  height: 24px;
  padding: 0px;
}

.gj-tree-md-drag-el [data-role="indicator"] {
  width: 24px;
  height: 24px;
  padding: 0px;
}