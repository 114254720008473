/*Modul 011700 accordion*/
/*Formular*/
#__new_participant{
  max-width: 700px;
  .cel-button-wrapper .btn{
    margin: 0;
  }
}
.necessary_field {
  color: red;
  font-weight: bold;
}
/*Main Body*/
.container,.container-fluid{
  background-color:white;
  padding:0;
}
/*Header*/
.cel-logo{
  padding-left: 0;
  padding-bottom: 10px;
  &.container{
    a{
      display: inline-block;
    }
  }
}

.cel-email-is-not-valid{
  background-color: #ffbfcd;
}

.cel-email-is-valid{
  background-color: #e0ffe0;
}