#cel-011912{

  .ajax-loader {
    visibility: hidden;
    background-color: #9999998f;
    margin-top: -10px;
    margin-left: -30px;
    position: absolute;
    z-index: +100 !important;
    width: 100%;
    height:100%;
  }

  .ajax-loader img {
    position: relative;
    top: 25%;
    margin: auto;
  }

  .table.cel-table-overview{
    .fa.fa-caret-down {
      display: none;
    }
    .fa.fa-caret-right {
      display: inline-block;
    }
    .cel-collapse{
      .fa.fa-caret-right {
        display: none;
      }
      .fa.fa-caret-down {
        display: inline-block;
      }
    }
    .collapse.in{
      background-color: #f5f5f5;
    }
    .cel-child-1 td:first-child{
      padding-left: 15px;
    }
    .cel-child-2 td:first-child{
      padding-left: 25px;
    }
    .cel-child-3 td:first-child{
      padding-left: 35px;
    }
    .cel-child-4 td:first-child{
      padding-left: 45px;
    }
    th {
      background: #3396c4;
      border-right: 1px solid #42a2cf !important;
      background: -webkit-linear-gradient(top, #3396c4, #2884ae);
      background: -moz-linear-gradient(top, #3396c4, #2884ae);
      background: -ms-linear-gradient(top, #3396c4, #2884ae);
      background: -o-linear-gradient(top, #3396c4, #2884ae);
      color: #FFF;
    }
  }
  .cel-tab{
    background-color: #f9f9f7;
    div.form-horizontal,
    form{
      padding: 10px;
      background-color:inherit;
      padding-bottom: 50px;
      .cel-save,
      .cel-save-casereport{
        margin-right: 5px;
      }
      .cel-save:first-child,
      .cel-save-casereport:first-child{
        margin-right: 0px;
      }

    }
  }
  .cel-new-record{
    padding: 10px;
  }
  .cel-file-upload-content,
  .fm_bt_file_upload{
    display: none;
  }
  .fm_bt_file_upload.active,
  .cel-file-upload-content.active{
    display: inline-block;
  }

  .cel-achievement_record{
    padding: 10px 10px 10px 30px;
    position: relative;
  }

  .cel-my-achievement_record{
    padding: 10px;
  }
  .medium{
    text-align: left;
  }
  .cel-status .fa{
    font-size: 22px;
  }

  .cel-trash{
    color:#d9534f;
  }

  .cel-draft{
    color:#FFFFAA;
  }

  .active_element{
    color: #5cb85c;
  }
  .inactive_element{
    color:#d9534f;
  }

  .pending_element{
    color:#f0ad4e;
  }
}

.cel-achievement_record-wrap-out{
  .cel-achievement_record-wrap-in{
    position: relative;
    width: 100%;
    height: 100%;
  }
  .cel-info-text{
    position: absolute;
    top: 10%;
    margin-left: 30px;
    padding: 5px;
    display: none;
    opacity: 0;
    transition: all 1s;
    font-size: 18px;
    font-weight: bold;
  }

  position: absolute;
  left: 0;
  top: 0;
  width:0;
  z-index: +100 !important;
  height:100%;
  transition: width 0.2s ease-in-out;
  .cel-select-ar{
    position: absolute;
    top: 45%;
    padding: 12px 5px 12px 6px;
    border-radius: 0 5px 5px 0;
    background-color: #3396C4;
    border: none;
    font-size: 17px ;
    .fa-caret-right{
      display: inline-block;
    }
    .fa-caret-left{
      display: none;
    }
  }

  #achievement_record{
    padding: 0;
    height:100%;
    display: none;
    color: transparent;
    background-color: #3396C4;
    ul.gj-list-md li [data-role="display"],
    ul.gj-list-md{
      color: #fff;
    }
  }

  &.active{
    background-color: #464444c4;
    color: #FFF;
    width:100%;
    .cel-info-text{
      opacity: 1;
      display: inline-block;
    }
    #achievement_record{
      height: 100%;
      padding: 5px 10px;
      display: inline-block;
      color: #fff;
    }
    .cel-select-ar{
      .fa-caret-right{
        display: none;
      }
      .fa-caret-left{
        display: inline-block;
      }
    }
  }
}

//.cel-tab{
//  min-height: 324px;
//}

.cel-form-edit-ar,
.cel-achievement_record{
  display: none;
}

.cel-form-edit-ar.active,
.cel-achievement_record.active{
  display: block;
  min-height: 324px;
}
