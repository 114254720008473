@font-face{
  font-family:"Quasimoda W05 BlackItalic";
  src:url("/fonts/quasimoda/5422715/0bcdf43d-88b6-4605-9a48-eeeb2b8f046f.eot?#iefix");
  src:url("/fonts/quasimoda/5422715/0bcdf43d-88b6-4605-9a48-eeeb2b8f046f.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422715/798d2955-28b9-4282-9d8f-168a464b1f4b.woff2") format("woff2"),url("/fonts/quasimoda/5422715/5cc76994-d980-43e8-8250-b68bdf2a3865.woff") format("woff"),url("/fonts/quasimoda/5422715/17555ef1-46df-4921-8cc6-0980b03074d9.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 Black";
  src:url("/fonts/quasimoda/5422721/3fad8f42-c01e-4f3d-8310-09ef09008fce.eot?#iefix");
  src:url("/fonts/quasimoda/5422721/3fad8f42-c01e-4f3d-8310-09ef09008fce.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422721/d6192ea7-3597-4f64-9fe4-41dd8ac64f94.woff2") format("woff2"),url("/fonts/quasimoda/5422721/03afcf91-d193-41ca-b30b-8cb4021e459a.woff") format("woff"),url("/fonts/quasimoda/5422721/3cbc0408-1c43-402b-a983-4e9240962d63.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 HeavyItalic";
  src:url("/fonts/quasimoda/5422729/bf286c12-aebf-4511-9946-47e568774395.eot?#iefix");
  src:url("/fonts/quasimoda/5422729/bf286c12-aebf-4511-9946-47e568774395.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422729/51241379-f34f-4dae-ba68-c8f93fb6a040.woff2") format("woff2"),url("/fonts/quasimoda/5422729/33cbe3e9-d683-46b8-9c88-8d1a4b60f02a.woff") format("woff"),url("/fonts/quasimoda/5422729/92f42c72-c24c-4837-84e0-0220d29f6ed4.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 Heavy";
  src:url("/fonts/quasimoda/5422731/34e90942-fa4f-47aa-9903-5922929ba07e.eot?#iefix");
  src:url("/fonts/quasimoda/5422731/34e90942-fa4f-47aa-9903-5922929ba07e.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422731/4309e5e6-3cf4-4a53-994f-ab77186f67e6.woff2") format("woff2"),url("/fonts/quasimoda/5422731/98b7e937-4365-451a-b300-4a470efa4ba1.woff") format("woff"),url("/fonts/quasimoda/5422731/edd6da51-8ff6-41c9-80ea-cdc879751463.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 ExtraBoldItalic";
  src:url("/fonts/quasimoda/5422735/03429023-b01b-4327-b947-70cb187f04c2.eot?#iefix");
  src:url("/fonts/quasimoda/5422735/03429023-b01b-4327-b947-70cb187f04c2.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422735/7ff69e84-4705-425b-9f5c-de3ff544dd60.woff2") format("woff2"),url("/fonts/quasimoda/5422735/29d7e99f-45ba-4d47-9d70-a0d38c5a1f2b.woff") format("woff"),url("/fonts/quasimoda/5422735/6fc95af4-0e0d-4965-94ac-a5e492d92660.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 ExtraBold";
  src:url("/fonts/quasimoda/5422745/b40fcae9-5e3b-4284-9170-0fff7c1516d4.eot?#iefix");
  src:url("/fonts/quasimoda/5422745/b40fcae9-5e3b-4284-9170-0fff7c1516d4.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422745/52d2c785-f285-43cb-90fc-92c2caf3ae3e.woff2") format("woff2"),url("/fonts/quasimoda/5422745/ec626d2f-4997-4760-916d-3f419020d67c.woff") format("woff"),url("/fonts/quasimoda/5422745/885d9279-caef-4ea1-a59c-65c882bea223.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 BoldItalic";
  src:url("/fonts/quasimoda/5422753/401369bc-e6a6-4583-8773-3666eff2eca7.eot?#iefix");
  src:url("/fonts/quasimoda/5422753/401369bc-e6a6-4583-8773-3666eff2eca7.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422753/9c2a8bc1-af30-4349-8a62-7321145570e1.woff2") format("woff2"),url("/fonts/quasimoda/5422753/d28eac31-6530-440c-b5ed-a5728e2351c0.woff") format("woff"),url("/fonts/quasimoda/5422753/9ecb4387-d75c-4933-8413-33c91772c561.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 Bold";
  src:url("/fonts/quasimoda/5422755/c7b57d5c-bf2d-478c-a5f5-efb0763bf65c.eot?#iefix");
  src:url("/fonts/quasimoda/5422755/c7b57d5c-bf2d-478c-a5f5-efb0763bf65c.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422755/d290d34c-4745-4e2a-866c-c8bd7062b603.woff2") format("woff2"),url("/fonts/quasimoda/5422755/66b90a5d-e9c2-44c9-b302-4e53074bab0b.woff") format("woff"),url("/fonts/quasimoda/5422755/549c4fa0-5c2a-4c66-aff2-c0924de59acc.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 SemiBoldItalic";
  src:url("/fonts/quasimoda/5422759/1ae0828f-ec16-490c-ba6a-3b6653fcccf6.eot?#iefix");
  src:url("/fonts/quasimoda/5422759/1ae0828f-ec16-490c-ba6a-3b6653fcccf6.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422759/98fe6bf4-1d55-4fa5-b9ed-4b2875a35766.woff2") format("woff2"),url("/fonts/quasimoda/5422759/966aa908-b49f-49ea-8e21-5992a60328a3.woff") format("woff"),url("/fonts/quasimoda/5422759/310fab7d-4b4e-40f7-a563-54ca3711b790.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 SemiBold";
  src:url("/fonts/quasimoda/5422771/169ab57c-e453-423e-9350-ba9ca28d64bb.eot?#iefix");
  src:url("/fonts/quasimoda/5422771/169ab57c-e453-423e-9350-ba9ca28d64bb.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422771/040177d0-29c6-47e2-8783-dba6e406c7cb.woff2") format("woff2"),url("/fonts/quasimoda/5422771/84bfe414-2fee-4dff-920b-6e448e63a369.woff") format("woff"),url("/fonts/quasimoda/5422771/d08c7330-3a84-482b-9533-3f0abff607be.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 MediumItalic";
  src:url("/fonts/quasimoda/5422775/35ab31fe-13de-4067-b708-909938c5c3f1.eot?#iefix");
  src:url("/fonts/quasimoda/5422775/35ab31fe-13de-4067-b708-909938c5c3f1.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422775/62af71be-ac1b-4c35-9d2f-9e21391f274e.woff2") format("woff2"),url("/fonts/quasimoda/5422775/6ae4a9eb-e2ac-4a5b-81c5-0ed459028292.woff") format("woff"),url("/fonts/quasimoda/5422775/2c8bec0e-cf49-41b0-9e81-8837409a891a.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 Medium";
  src:url("/fonts/quasimoda/5422777/8ccab64d-24f6-46d9-bca7-faf8ea423fec.eot?#iefix");
  src:url("/fonts/quasimoda/5422777/8ccab64d-24f6-46d9-bca7-faf8ea423fec.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422777/04aecfa6-eaea-4c5e-bcce-cdb33e29de4a.woff2") format("woff2"),url("/fonts/quasimoda/5422777/bad3a39b-5c72-487f-9130-2d184f11ffc7.woff") format("woff"),url("/fonts/quasimoda/5422777/2a458472-7ea9-482e-837d-9743da3cbff3.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 Italic";
  src:url("/fonts/quasimoda/5422787/4788047a-e2ae-4160-833d-fb7590b39a5d.eot?#iefix");
  src:url("/fonts/quasimoda/5422787/4788047a-e2ae-4160-833d-fb7590b39a5d.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422787/422efe9b-c85c-4d38-9916-fa9fe321e006.woff2") format("woff2"),url("/fonts/quasimoda/5422787/4163c33e-d08c-47b6-93d8-caad4754c60b.woff") format("woff"),url("/fonts/quasimoda/5422787/5fe68c12-5073-4b77-86ba-6f649202136a.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 Regular";
  src:url("/fonts/quasimoda/5422795/e7c24f3d-fcaf-4b0b-83ec-517dd862df19.eot?#iefix");
  src:url("/fonts/quasimoda/5422795/e7c24f3d-fcaf-4b0b-83ec-517dd862df19.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422795/2ba880cf-5c01-42c3-ab0e-d725e5cd46dc.woff2") format("woff2"),url("/fonts/quasimoda/5422795/8758e0cd-5291-49ff-9d5f-9aa9fb48a9b4.woff") format("woff"),url("/fonts/quasimoda/5422795/12c8655a-7b2c-4498-9848-306ba3b74c7b.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 LightItalic";
  src:url("/fonts/quasimoda/5422803/117c74e4-47eb-43fb-8af1-afbad9e4bdcb.eot?#iefix");
  src:url("/fonts/quasimoda/5422803/117c74e4-47eb-43fb-8af1-afbad9e4bdcb.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422803/b288ece1-f9e1-426a-88bf-832caad4285a.woff2") format("woff2"),url("/fonts/quasimoda/5422803/d5241a30-e74c-4fd8-b39a-24d35f6de763.woff") format("woff"),url("/fonts/quasimoda/5422803/cb1885f5-99f4-499a-af2b-06209e8d2f3a.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 Light";
  src:url("/fonts/quasimoda/5422805/0ee1d04e-b1c7-40bb-9ccc-63b955ec1ba2.eot?#iefix");
  src:url("/fonts/quasimoda/5422805/0ee1d04e-b1c7-40bb-9ccc-63b955ec1ba2.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422805/c9e60161-de78-4a0a-b3ed-b8f013bb29c3.woff2") format("woff2"),url("/fonts/quasimoda/5422805/118dc534-09b2-408f-aef4-bd68cf2a7e02.woff") format("woff"),url("/fonts/quasimoda/5422805/68577e2d-5d93-4040-993c-405f040b233b.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 ExtraLightItalic";
  src:url("/fonts/quasimoda/5422812/b6f35e3d-55eb-49b9-b7bf-af84b3062ca0.eot?#iefix");
  src:url("/fonts/quasimoda/5422812/b6f35e3d-55eb-49b9-b7bf-af84b3062ca0.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422812/13fa008c-3122-462a-ae8d-39c36d14e7c1.woff2") format("woff2"),url("/fonts/quasimoda/5422812/a309630e-54b9-48f3-b087-60cc8da0268a.woff") format("woff"),url("/fonts/quasimoda/5422812/8e6e1541-4a94-47c5-a6e8-662cb317a864.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 ExtraLight";
  src:url("/fonts/quasimoda/5422816/fc0be840-5b9a-4254-b1e2-4e0230dfdc2f.eot?#iefix");
  src:url("/fonts/quasimoda/5422816/fc0be840-5b9a-4254-b1e2-4e0230dfdc2f.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422816/fd3baa48-f079-49cd-a307-867698c8288a.woff2") format("woff2"),url("/fonts/quasimoda/5422816/c3c425e4-cfc4-4882-866e-2d8e138c9cdf.woff") format("woff"),url("/fonts/quasimoda/5422816/c00d20ce-d6cf-4094-b446-84aaec351811.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 ThinItalic";
  src:url("/fonts/quasimoda/5422822/ae09cba2-65ce-4f97-9e8c-136bace4602d.eot?#iefix");
  src:url("/fonts/quasimoda/5422822/ae09cba2-65ce-4f97-9e8c-136bace4602d.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422822/b7da769d-0db5-4416-9aa6-454a8430f67e.woff2") format("woff2"),url("/fonts/quasimoda/5422822/e167fb7f-c476-4fb3-90ed-045974df72e8.woff") format("woff"),url("/fonts/quasimoda/5422822/1b557e25-b549-4b06-80c4-d4431108d597.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 Thin";
  src:url("/fonts/quasimoda/5422830/5c01cd45-f005-4a38-bc4b-bed26e872279.eot?#iefix");
  src:url("/fonts/quasimoda/5422830/5c01cd45-f005-4a38-bc4b-bed26e872279.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422830/4c37ccf6-49c0-4643-808c-1a85ed9cb66a.woff2") format("woff2"),url("/fonts/quasimoda/5422830/131f2010-da95-4f9f-9d57-4a047508180c.woff") format("woff"),url("/fonts/quasimoda/5422830/f38ba782-d89f-4827-a901-08ba1fc2ecda.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 HairLineItalic";
  src:url("/fonts/quasimoda/5422836/dccc460f-fa3e-4bfb-8999-2cfc414d4671.eot?#iefix");
  src:url("/fonts/quasimoda/5422836/dccc460f-fa3e-4bfb-8999-2cfc414d4671.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422836/19640f04-87d6-4a58-8260-39bf6de24df8.woff2") format("woff2"),url("/fonts/quasimoda/5422836/9d88f5fa-8b46-4e8b-8b46-db067ed0b7a2.woff") format("woff"),url("/fonts/quasimoda/5422836/740fcee8-a55b-4f82-ae60-8ef1a2591e79.ttf") format("truetype");
}
@font-face{
  font-family:"Quasimoda W05 HairLine";
  src:url("/fonts/quasimoda/5422844/4cb36c6f-8313-4bf3-84bf-28cc1e10dfdd.eot?#iefix");
  src:url("/fonts/quasimoda/5422844/4cb36c6f-8313-4bf3-84bf-28cc1e10dfdd.eot?#iefix") format("eot"),url("/fonts/quasimoda/5422844/3260dadd-9155-4dbc-bcc8-08be502c4154.woff2") format("woff2"),url("/fonts/quasimoda/5422844/3e9e629b-c2c3-4881-a207-804dd8a0ba2b.woff") format("woff"),url("/fonts/quasimoda/5422844/ef4acbd5-97d0-4c59-b106-98eeaa68f23d.ttf") format("truetype");
}