/*Modul 011300 */

.cel-layout-wrapper{
  width : 100%;
  margin-bottom: 20px;
}

.cel-category-link{
  display: block;
}

.cel-layout-menu-style{
  box-shadow: 1px 3px 13px 0px rgba(0,0,0,0.48);
  padding: 10px 10px 50px 10px;
  margin-bottom: 20px;
  height: 100%;
  background-color: #FFF;
}

.cel-top-menu{
  margin-bottom: 20px;
}

.cel-card-wrapper{
  border-style: solid;
  border-width: 2px;
  border-color: $primary-color;
  background-color: transparent;
  min-height: 200px;
  padding:0 10px 10px 10px;
  float:left;
  position: relative;
  width: 100%;
  height: 100%;
}

.cel-card-art-tag{
  position:relative;
  top: 0px;
  left: 30px;
  background-color:$primary-color;
  color:#FFF;
  display: inline-block;
  height:25px;
  padding: 4px;
  margin-right:10px;
}

.cel-card-art-no-tag{
  height:25px;
}

.cel-card-dat-name{
  margin-top: 10px;
  font-size: 18px;
  font-weight: bolder;
  color: $primary-color;
  padding-bottom: 10px;
}

.cel-card-dat-desc{
  padding-bottom: 10px;
}

.cel-card-dat-terms{
  position: relative;
  border-top: 1px solid $primary-color;
  margin-left: -10px;
  margin-right: -10px;
  padding: 10px 10px 0;
  margin-bottom: 10px;
  min-height: 55px;
  .fa-clock-o{
    padding-right: 10px ;
  }
  .btn-group{
    position: absolute;
    right: 10px;
    bottom: 0;
  }
}

.cel-card-button-div{
  height:60px;
}

.cel-card-dat-button-pos{
  position:absolute;
  bottom: 10px;
  right: 10px;
}

.cel-layouts-header{
  background-color: $primary-color;
  color: white;
  font-weight: bold;
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 12px;
  padding: 10px;
}

.cel-row-container-one {
  display: grid;
  grid-template-columns: 1fr; /*fraction*/
}

.cel-row-container-two {
  display: grid;
  grid-template-columns: 1fr 1fr; /*fraction*/
}

.cel-row-container-three {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /*fraction*/
}

.cel-row-section {
  padding:10px;
}


.cel-zoom-card {
  transition: transform .2s;
}

.cel-zoom-card:hover {
  transform: scale(1.03);
  background-color: #e2f4ff;
  -webkit-box-shadow: 1px 3px 13px 0px rgba(0,0,0,0.48);
  -moz-box-shadow: 1px 3px 13px 0px rgba(0,0,0,0.48);
  box-shadow: 1px 3px 13px 0px rgba(0,0,0,0.48);
}

.cel-cat-meta-header{
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

#cel-cards-row{
  .cel-card-wrapper .cel-card-art-tag:first-child{
    display: none;
  }
}

@media (max-width: 600px) { /*breakpoint*/
  .cel-row-container-one {
    grid-template-columns: none;
  }

  .cel-row-container-two {
    grid-template-columns: none;
  }

  .cel-row-container-three {
    grid-template-columns: none;
  }

}