/*Footer*/
footer.cel-footer-wrapper{
  background-color: $additional-color;
  .cel-footer-inner-wrapper {
    padding: 16px 0px 16px;
    color: #FFF;
    background-color: transparent;
    a{
      color: #FFF;
    }
  }
  li{
    display: block;
  }
  .cel-subfooter{
    background-color: $secondary-color;
    text-align: center;
    padding: 10px 30px;
    margin-left: -15px;
    margin-right: -15px;
    clear: both;
    .cel-copyright{
      display: none;
    }
    &:after {
      content: " ";
      display: table;
      clear: both;
    }
    p{
      margin:0;
      color: $gray-3-color;
    }
    ul li{
      float: left;
      a {
        text-decoration: none;
        color: #004477;
      }
    }
    .cel-footermenu{
      float: right;
      padding-left:5px ;
    }
  }
  .cel-footer{
    text-align: right;
  }
  .wpex-info-widget .fa {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1.231em;
  }
  .wpex-info-widget div {
    padding-left: 2.308em;
    position: relative;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  #footer-menu-social{
    display: inline-block;
    padding: 0;
    margin: 0;
    font-weight: 900;
    li{
      float:left;
      padding-right: 10px;
      font-size: 16px;
    }
  }
  .site-info__text{
      display: inline-block;     
      color: $gray-3-color;
  }
  .cel-footermenu{
    float:right;
    padding-left: 10px;
  }
}
