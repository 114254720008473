
.has-float-label input, .has-float-label select, .has-float-label textarea{
  margin: 0;
}

.cel-upload-file-div {
  margin-left:20px;
  background:#f2f2f2;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: 60px 100%;
  grid-template-areas:
          "select selected"
          "dragdrop selected";
}

.cel-drag-drop-div{
  grid-area: dragdrop;
  vertical-align: top;
  padding: 10px;
}

.cel-drag-area{
  border: 2px;
  border-style: dashed;
  border-color: #0C0C0C;
  height: 130px;
  border-radius: 10px;
  text-align: center;
  line-height: 130px;
  font-size: 20px;
  color:lightgrey;
  background-color: white;
}

.cel-upload-files-div{
  border: 2px;
  border-style: solid;
  height: 100%;
  padding:10px;
  background-color: white;
  border-radius: 10px;
}

.cel-selected-files-div{
  padding: 10px;
  grid-area: selected;
}

.cel-select-file-div{
  padding: 10px;
  grid-area: select;
}

.cel-file-icon-div{
  margin-right: 10px;
}

@media only screen and (max-width: 600px) {
  .cel-upload-file-div{
    display: block;
  }
}