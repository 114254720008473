/*Modul 011200*/

a.btn.btn-default.target_group_remove {
  margin-top: 20px;
}

/*Headline*/
.qa-container h2 {
  padding-top: 30px;
  text-align: center;
  font-weight: normal;
}
.qa-container {
  margin-top: 20px;
  background: #F8F8F8;
}
.qa {
  padding: 0 20px 20px !important;
  p{
    margin-left: 10px;
    color: #000;
    display: none;
    font-size: 13px;
  }
  h3{
    font-size: 14px;
    color: #5F5F5F;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 5px;
    font-weight: 400;
    padding-bottom: 0;
    line-height: 14px;
    margin-top: 10px;
    margin-left: 0;
    display: block;
  }
}

.seminarsuche{
  .cel-buttons-right:last-child{
    margin-right: 10px;
  }
}

/* accodion */
.cel-011200 {
  .panel{
    box-shadow:none;
  }

  .panel-heading {
    a.cel-collapsed {
      float: left;
      padding-right: 10px;

      i {
        font-size: 18px;
      }

      &:hover,
      &:focus {
        text-decoration: none;
      }

      &.collapsed {
        .fa-window-maximize {
          display: inline-block;
        }

        .fa-window-minimize {
          display: none;
        }
      }

      .fa-window-maximize {
        display: none;
      }
    }
  }

  .panel.panel-default {
    &.cel-accordion-below {
      padding-bottom: 39px;
    }

    border: none;
    border-bottom: 1px solid #E7E7E7;

    a.cel-button-accordion {
      padding-top: 10px;
      float: right;

      &:hover,
      &:focus {
        text-decoration: none;
      }

      &.collapsed {
        .cel-more,
        .cel-plus {
          display: inline-block;
        }

        .cel-less,
        .cel-minus {
          display: none;
        }

        .cel-minus, .cel-plus {
          background-color: #247dc4;
        }

        .cel-text {
          background-color: #E5EFF8;
          color: #005C9E;
        }
      }

      .cel-text,
      .cel-minus,
      .cel-plus {
        display: inline-block;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 5px;
      }

      .cel-plus {
        width: 42px;
        color: #fff;
        background-color: #838383;
        display: none;
      }

      .cel-more {
        display: none;
      }

      .cel-minus {
        width: 42px;
        color: #fff;
        background-color: #838383;
      }

      .cel-text {
        padding-left: 5px;
        padding-right: 5px;
        margin-right: -3px;
        background: #D3D3D3;
      }
    }
  }

  .panel-heading {
    padding-left: 0;

    h2.panel-title {
      font-size: 18px;
      font-weight: bold;
      color: #005C9E;
      padding-bottom: 18px;
      line-height: 22px;
    }
  }

  .cel-infoline-button {
    text-align: right;
  }

  .panel-body .col-sm-3 h3 {
    margin: 0;
    padding-bottom: 10px;
  }

  .cel-date, .cel-time {
    display: inline-block;
    margin-right: 20px;
  }

  .infoline {
    p {
      margin-bottom: 0;
    }

    .cel-price,
    .cel-titel {
      display: inline-block;
    }
  }

  #accordion {
    .panel-body {
      .col-sm-12:first-child {
        h3 {
          margin-top: 0;
        }
      }

      button {
        padding: 7px 33px 7px 32px;
      }

      padding: 0;

      .cel-panel-content {
        padding-bottom: 10px;
      }

      .cel-infoline-button {
        float: left;
      }

      .cel-infoline-button:last-child {
        float: right;
      }
    }
  }
}
.progress{
  background-color: #e5e6e7;
}

.cel-Left{
  .cel-target_group_button{
    padding: 5px 0;
  }
  a.btn.btn-default.target_group_remove{
    margin: 0 10px 0;
  }
  i.fa.fa-minus,
  i.fa.fa-plus {
    padding: 0;
  }
}