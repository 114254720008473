// Core variables and mixins
$screen-xxs-min: 320px;
$screen-xxs-max: ($screen-sm-min - 1);
$celanio-blue: #158fd0;

//vdpPfandbrief variable
$primary-color: #861d48;
$secondary-color: #626266;
$additional-color: #554684;

$gray-1-color:#878787;
$gray-2-color:#9d9d9c;
$gray-3-color:#c6c6c6;

$main-font: "Quasimoda W05 Light","Helvetica", "Arial", sans-serif;
$header-font: "Quasimoda W05 SemiBold","Helvetica", "Arial", sans-serif;
$headersmall-font: "Quasimoda W05 Medium","Helvetica", "Arial", sans-serif;
$navbutton-font: "Quasimoda W05 Regular","Helvetica", "Arial", sans-serif;