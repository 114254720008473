.module011910 .cel-dateEventContent >.row{
  display: flex;
}

.module011910 .cel-dateEventContent .cel-dateContainer{
  background: #f4f4f4;
  height: 100%;
  position: relative;
}

.module011910 .cel-date {
  display: block;
  color: #969696;
  padding: 10px;
  text-align: center;
  margin: 0;
  .cel-day {
    display: block;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 2px;
  }
}
.module011910 .cel-end-date {
  position: absolute;
  bottom: 0;
  left:0;
  right: 0;
  display: block;
  border: 2px solid #f4f4f4;
  text-align: center;
  padding: 4px;
  background-color: #FFF;
}
.module011910 .cel-title h2{
  font-size: 18px;
  font-weight: bold;
  color: #005C9E;
  padding: 10px 0;
  margin: 0;
  line-height: 22px;

}
.module011910 .cel-eventContent h3 {
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  margin: 0;
}

.cel-dateEventContent {
  display: block;
  border: 1px solid #e7e7e7;
  margin-bottom: 30px;
}
.cel-modalButton a {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  padding: 10px;
  cursor: pointer;
}
.module011910 .cel-form-wrapper{
  margin-bottom: 10px;
  max-width: inherit;
  .btn-default{
    margin-right: 10px;
  }
}
.module011910 #view_filter{
  max-width: inherit;
}

.module011910 a.cel-select-remove {
  margin-top: 20px;
}

.module011910 .cel-dateContent{
  margin-bottom: 10px;
}