/* Extranet Login - Single Sign On*/
#cel-sso-superior-search {
  #cel-superiors-found {
    list-style-type: none;
    padding: 0;
    li {
      padding: 8px 0;
      input, label {
        vertical-align: middle;
      }
      input {
        margin: 0 4px 0 0;
      }
      label {
        margin: 0;
      }
    }
  }

  #cel-ajax-loader {
    span {
      margin-left: 4px;
    }
  }
}