/**
 * This is a customizable CSS file where classes can be
 * added that appear in the celanio backend web-editor.
 *
 * If necessary override this file in customer space.
 */
// Core customer variables
@import "bootstrap/variables";
@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "../node_modules/font-awesome-sass/assets/stylesheets/font-awesome";
@import "float-label/float-label";
@import "customer/sso";
@import "customer/quasimoda";
@import "customer/variables";
@import "bootstrap/tree-view";

@import "module/010600";
@import "module/010700";
@import "module/010800";
@import "module/010900";
@import "module/011200";
@import "module/011300";
@import "module/011700";
@import "module/011800";
@import "module/011901";
@import "module/011905";
@import "module/011910";
@import "module/011911";
@import "module/011912";
@import "module/011919";
@import "module/011922";
@import "module/013100";
@import "module/014000";
@import "module/070100";

/*Default*/
h1, .h1, h2, .h2{
    font-family: $header-font;
    color: #6f6f6f;
}

h3, .h3, h4, .h4,h5, .h5,h6, .h6{
    font-family: $headersmall-font;
    color: #6f6f6f;
}

h1, .h1 {
    font-size: 31px;
}
h2, .h2 {
    font-size: 25px;
}
h3, .h3 {
    font-size: 21px;
}
h4, .h4 {
    font-size: 18px;
}
h5, .h5 {
    font-size: 16px;
}
h6, .h6 {
    font-size: 14px;
}

a {
    color: $primary-color;
    text-decoration: none;
}

.cel-active > a {
    color: #23527c;
    text-decoration: underline;
}

.btn{
    font-size: 16px;
}
.btn-default {
    background-color: $gray-3-color;
    border-color: $gray-3-color;
}

.btn-primary {
    color: $additional-color;
    background-color: transparent;
    border-color: $additional-color;
}

.btn-primary:hover {
    color: #FFF;
    background-color: $additional-color;
    border-color: $additional-color;
}
.cel-error{
    border-color: $primary-color;
}
#topnavaccordion{
    .navbar-nav {
        float: right;
    }
    span.caret{
        display: none;
    }
    li.active {
        a.head {
            color:$additional-color;
        }
    }
    a.head{
        font-family: $main-font;
        padding: 12px;
        position: relative;
        display: block;
        color: $secondary-color;
        background-color: transparent;
        transition: color .3s ease, background-color .3s ease;
        flex-grow: 1;
        //background-color:$gray-3-color;
        line-height: 1.6;
        text-transform: uppercase;
        &:hover, &:active,&:focus{
            text-decoration: none;
            color:$additional-color;
            //background-color: $primary-color;
        }
        a.head,
        a.sub{
            white-space: nowrap;
        }
    }
}

.table{
    background-color: #FFF;
}
.table > thead > tr > th{
    border: none;
}
.cel-menuhide{
    display: none;
}

.cel-space{
    margin-top: 10px;
}
.cel-bottom-separator{
    border-bottom: 1px solid #e5e5e5;
}

.cel-smiley {
    height:  22px;
    width:   22px;
    display: inline-block;
}

.cel-smiley-1 {
    background: url("../../gfx/22x22/emotes/face-smile.png") no-repeat;
}

.cel-smiley-2 {
    background: url("../../gfx/22x22/emotes/face-plain.png") no-repeat;
}

.cel-smiley-3 {
    background: url("../../gfx/22x22/emotes/face-sad.png") no-repeat;
}

.panel-body .col-sm-3 h3 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 5px;
    padding-bottom: 10px;
}

.control-label em,
.cel-mandatory-field,
.necessary_field{
    color: red;
}
.cel-button{
    margin-right: 10px;
}
.cel-button-wrapper{
    .btn{
        margin-right: 10px;
    }
}
/* Background*/
html,body{
    font-family: $main-font;
    font-size: 16px;
    font-weight: 300;
    height: 100%; /* needed for container min-height */
}

.cel-page{
    position: relative;
}

.cel-backgroundcolor{
    background-color: $gray-3-color;
}

.cel-backgroundimage{
    background-position: center top;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

/*Main Body*/
.container,.container-fluid{
    background-color:transparent;
    padding:0;
    overflow-x: hidden;
}
/*Header*/
.cel-page .cel-logo{
    padding: 32px 0;
    max-width: 138px;
    display: inline-block;
}
.cel-logo.cel-top{
    img{
        max-width: 290px;
        padding: 20px;
        display: inline-block;
    }
    .search{
        padding: 20px;
        float: right;
        display: inline-block;
        width: 300px;
        .btn-primary {
            height: 36px;
            min-width: 36px;
            padding: 6px 7px;
        }
    }
}

.cel-header{
    border-top: 4px solid $additional-color;
    border-bottom: 4px solid $gray-3-color;
    background-color: #FFF;
}
nav.navbar{
    border: none;
    margin-bottom: 0;
    position: relative;
    //border-bottom: 1px solid #e7e7e7;
    border-radius: 0;
    .navbar-collapse {
        padding-top: 32px;
    }
    .search{
        float: right;
        form{
          padding: 7px 0;
        }
    }
}

.cel-banner{
    img{
        width: 100%;
    }
}

/*Content*/
.container-fluid .cel-content,
.container .cel-content{
    padding: 30px;
    background-color: #ededed;
}

ul.cel-sidemenu{
    padding-top: 20px; padding-bottom: 20px;
    > li{
        border-bottom: 1px solid #737373;
    }
    > li:last-child{
        border-bottom: none;
    }
    ul.dropdown-menu{
        position: relative;
        float: none;
        border: none;
        border-radius: 0;
        box-shadow: none;
    }
}

.cel-avatar-container {
    background-color: $primary-color;
    position: relative;
    min-height: 44px;
    border-bottom: 1px solid #FFF;
    .cel-avatar {
        color: #FFF;
        padding: 10px;
        img {
            margin-left: 10px;
            display: inline-flex;
            height: 20px;
            width: 20px;
            border-radius: 50%;
        }
        form{
            display: inline-flex;
            margin: 0;
        }
        button{
            margin-left: 20px;
            padding: 4px;
            border-radius:50px ;
        }
    }
}

#cel-webshop-switch{
    max-width: 800px;
    .row{
        display: flex;
    }
    .cel-btn-group{
        position: absolute;
        bottom: 20px;
        left: 28px;
        right: 28px;
    }
    form{
        margin-bottom: 40px;
    }
}

#cel-webshop-switch-register.cel-register,
#cel-webshop-switch-login.cel-login{
    border-right:none;
    .cel-btn-group{
        position: inherit;
    }
}

#cel-webshop-switch-login {
    border-right: solid 2px #a8a8af;
}

#cel-webshop-switch-login,
#cel-webshop-switch-register {
    padding: 0 28px;
    form{
        padding-bottom: 20px;
    }
}

.cel-avatar{
    color: #FFF;
    float: right;
    img {
        display: inline-flex;
        height: 50px;
        width: 50px;
        border-radius: 50%;
    }
}
@import "customer/footer";
@import "customer/responsive";