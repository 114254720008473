/*Standard*/
//$font-family-sans-serif: 'Arial',sans-serif;
//$font-size-base:          15px;
//$text-color: #383A4C;
//$container-lg: 1140px;

/*Navigation*/
//$navbar-height: 41px;
//$navbar-default-bg:                #FFF;
//$navbar-default-link-color:        #c52423;
//$navbar-default-link-hover-bg:     #C52423;
//$navbar-default-link-hover-color:  #FFF;
//$navbar-default-link-active-bg:    #C52423;
//$navbar-default-link-active-color: #FFF;

/*Panels*/
$panel-default-heading-bg: transparent;
$panel-default-border: transparent;
$panel-body-padding: 15px 0 15px 15px;
/*btn button primary*/
//$btn-primary-color: #FFF;
//$btn-primary-bg: #C52423;
$btn-border-radius-base: 0;
/*input box*/
$input-border-radius: 0;
/*Customer variable*/
$cel-background: #DCDBD1;
//$background-image: url(/cms/gfx/body-bg.jpg);
$background-image: none;