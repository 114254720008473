/*Login*/
.cel-login{
  .cel-login-content{
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    .btn-primary {
      margin-top: 0;
    }
  }
}