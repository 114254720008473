.superiorSelectionDialog {
  padding:            20px;
  color:              #000000;
  background-color:   #ffffff;
  border:             1px solid #cccccc;
  -webkit-box-shadow: -10px 0px 40px 0px rgba(0,0,0,0.4);
  -moz-box-shadow:    -10px 0px 40px 0px rgba(0,0,0,0.4);
  box-shadow:         -10px 0px 40px 0px rgba(0,0,0,0.4);
}

.superiorSelectionDialog input.evaluate {
  margin-right: 10px;
}

.superiorSelectionDialog .radio {
  margin-bottom: 10px;
}

.superiorSelectionDialog ul#cel-superiors-found {
  list-style-type: none;
  padding: 10px 0;
}

.superiorSelectionDialog ul#cel-superiors-found li input {
  margin: 0 5px;
  vertical-align: middle;
}


.superiorSelectionDialog ul#cel-superiors-found li {
  margin-bottom: 5px;
}

.superiorSelectionDialog ul#cel-superiors-found li label {
  display: inline;
  vertical-align: middle;
}

.superiorSelectionDialog #cel-ajax-loader img {
  margin-right: 10px;
}