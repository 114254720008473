.ui-dialog{
  padding: 10px;
  background-color: #e5e5e5;
}
button.ui-dialog-titlebar-close{
  float: right;
  &:after{
    content: 'x';
  }
}
#participantForms{
  h2{
    display: inline-block;
    margin: 0 0 10px 0;
  }
  .collapse.in{
    .fa-window-minimize{
      display: block;
    }
    .fa-window-maximize{
      display: none;
    }
  }
  .expand.in{
    .fa-window-minimize{
      display: none;
    }
    .fa-window-maximize{
      display: block;
    }
  }
  .cel-floatingIcons{
    float: right;
    a{
      float: left;
      font-size: 18px;
      padding-left: 5px;
      text-decoration:none;
      &:focus,
      &:active,
      &:hover{
        text-decoration:none;
      }
    }
  }
}
.cel-011911 {
  h1{
    font-size: 24px;
  }
  .cel-form-extranet-booking-form {
    .cel-bottom-separator,
    legend {
      border: none;
      padding-top: 5px;
    }
    #foot_add_participant {
      padding-bottom: 40px;
      #selectParticipantTable{
        margin-top: 10px;
      }
      .cel-add-participant{
        float: right;
        color: #59A6FF;
      }
    }
    .cel-choosen-participant {
      .cel-information{
        border-bottom: 3px solid hsla(0,0%,100%,.3);
        font-weight: bold;
        padding-bottom: 5px;
      }

      a{
        color: #FFF;
      }
      color: #FFF;
      background-color: rgba(125, 145, 165, .85);
      padding: 10px;
      #__related_contacts{
        border-top: 2px solid hsla(0,0%,100%,.3);
        padding-top: 5px;
      }
    }
  }
}
