@mixin float-label-container {
  display: block;
  position: relative;
}

@mixin float-label {
  background: white;
  label, > span {
    position: absolute;
    left: 13px;
    top: -14px;
    cursor: text;
    font-weight: normal;
    font-size: 11px;
    padding: 0 3px;
    transition: all .2s;
    @content;
  }
}

@mixin float-label-input {
  &::placeholder {
    opacity: 1;
    transition: all .2s;
  }
  &:placeholder-shown:not(:focus)::placeholder {
      opacity: 0;
  }
}

@mixin float-label-scaled {
  &:placeholder-shown:not(:focus) + * {
      opacity: .5;
      font-size: 14px;
      background-color: transparent;
      @content;
  }
}
