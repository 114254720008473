@import "mixins";

.has-float-label {
  @include float-label-container;

  @include float-label;

  select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }

  textarea {
    width: 100%;
  }

  input, select, textarea {
    @include float-label-input;
    //font-size: inherit;
    //padding-top: 1em;
    //margin-bottom: 2px;
    //border: 0;
    //border-radius: 0;
    //border-bottom: 2px solid rgba(0,0,0,.1);
    &::placeholder{
      color:#FFF;
    }
    @include float-label-scaled {
      top: 9px;
    }

    &:focus {
      outline: none;
      border-color: rgba(0,0,0,.5);
    }
  }

  select {
    padding-right: 1em;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .5em bottom .25em;
    background-size: 8px 10px;
  }
}
