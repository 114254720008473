/**
 * This file contains the layout for special areas in the internal section
 * of celanio, such as boxes for warning- or success messages.
 *
 * @author     Buchheim, Martin
 * @copyright  celanio GmbH
 */
a.tab {
  border-top-left-radius:  4px;
  border-top-right-radius: 4px;
  display:                 block;
  float:                   left;
  min-width:               1.05em;
  line-height:             20px;
  vertical-align:          middle;
  height:                  1.7em;
  background:              #F5F5F5;
  color:                   #444444;
  font-weight:             bold;
  margin:                  2px 2px 0 2px;
  padding:                 1px 3px 1px 3px;
  border:                  1px solid gray;
  border-bottom:           none;
  text-align:              center;
}

a.tab:hover, a.tab_active {
  border-top-left-radius:  4px;
  border-top-right-radius: 4px;
  display:                 block;
  float:                   left;
  min-width:               1.7em;
  height:                  1.7em;
  line-height:             20px;
  vertical-align:          middle;
  text-decoration:         none;
  background:              #2B9CDD;
  color:                   #FFFFFF;
  font-weight:             bold;
  margin:                  2px 2px 0 2px;
  padding:                 1px 3px 1px 3px;
  border:                  1px solid gray;
  border-bottom:           none;
  text-align:              center;
}

.tabtop a:first-child {
  margin-left: 0;
}

div.debug {
  margin:     30px auto;
  padding:    3px;
  width:      960px;
  text-align: center;
}

div.modctllink {
  display:       block;
  float:         left;
}

div.modctllink a {
  display:       block;
  padding-right: 10px;
  float:         left;
  height:        18px;
  font-weight:   bold;
}

div.modctl {
  margin:         5px 0;
  padding:        3px 0;
  min-height:     1.5em;
  line-height:    1.5em;
  vertical-align: middle;
  text-align:     left;
}

div.modctl:after {
  content:    ".";
  display:    block;
  height:     0;
  clear:      both;
  visibility: hidden;
}

div.modhead {
  display:        block;
  margin:         0.1em 0 0.1em 0;
  padding:        0 5px 0 5px;
  height:         2em;
  line-height:    2em;
  vertical-align: middle;
  background:     url(../gfx/bg_headline.png) repeat-y left;
  border-bottom:  1px solid #2B9CDD;
  border-left:    3px solid #2B9CDD;
}

div.modtree {
  font-size:       80%;
  color:           #000000;
  text-decoration: none;
  padding:         0 3px;
}

div.modtree a {
  color:       #000000;
  font-weight: normal;
}

/**
 * Boxes for system messages and documentation
 ******************************************************************************/

.cel-message-box {
  margin-bottom:   10px;
  padding:         15px;
  text-align:      justify;
  border:          1px solid black;
  border-radius:   4px;
  background-size: 22px;
}

.cel-warning {
  background: #F79A9A url(../gfx/48x48/status/dialog-warning.png) no-repeat right bottom;
}

.cel-success {
  background: #E0F8C9 url(../gfx/48x48/celanio/dialog-success.png) no-repeat right bottom;
}

.cel-notice {
  background: #FFFFB6 url(../gfx/48x48/status/dialog-information.png) no-repeat right bottom;
  background-size: 32px;
}

div#modHelpBox {
  display:    none;
  margin:     5px 0;
}

div#modHelpBox a {
  color:           #000000;
  text-decoration: underline;
}

div#modHelpBox h2 {
  font-weight:   bold;
  font-size:     1.0em;
  border-bottom: 1px solid #D6C090;
}

div#modHelpBox p {
  margin: 4px 40px 4px 0;
}

div.tabbox {
  padding: 5px;
  margin:  0;
  display: block;
  border:  1px solid gray;
}

div.tabtop {
  padding: 1px 0;
  margin:  10px 0 0 0;
  display: block;
  height:  23px;
  margin-bottom: 4px;
}

select.transferbox {
  font-family: "Courier New", monospace;
  font-size:   12px;
}

.cel-vertical-distance {
  margin: 30px 0;
}

select.illustrated option {
  padding:        0 3px 0 20px;
  margin:         1px;
  min-height:     16px;
  line-height:    16px;
  vertical-align: middle;
  background:     no-repeat left center;
}

/* TABLE-SORTER */
table.sortable {
  width:      100%;
  text-align: left;
}

table.sortable thead tr th, table.sortable tfoot tr th {
  padding: 4px;
  color:   #F8F8F8;
}

table.sortable thead tr .header {
  background:         url(../gfx/icons/sort_bg.gif) no-repeat center right;
  cursor:             pointer;
  color:              #FFFFFF;
  background-color:   #2B9CDD;
}

table.sortable tbody td {
  padding:        4px;
  vertical-align: top;
}

table.sortable thead tr .headerSortUp {
  background-image: url(../gfx/icons/sort_asc.gif);
}

table.sortable thead tr .headerSortDown {
  background-image: url(../gfx/icons/sort_desc.gif);
}

table.sortable thead tr .headerSortDown, table.sortable thead tr .headerSortUp {
  background-color: #FFFFFF;
  color:            #000000;
}

/* ----------------------------------------------------------------------------------------
						TOOLTIP & OVERLAY
 ---------------------------------------------------------------------------------------- */
div.tooltip {
  min-width:  300px;
  max-width:  500px;
  padding:    10px;
  border:     3px double gray;
  text-align: left;
  background: url(../gfx/bg_white_translucent.png);
  filter:     alpha(opacity=1);
  opacity:    1;
}

div.overlay {
  position:         absolute;
  z-index:          15;
  display:          none;
  top:              0;
  left:             0;
  width:            100%;
  height:           100%;
  background-image: url(../gfx/bg_overlay.png);
}

div.overlay div.ovlwindow {
  z-index:          15;
  position:         fixed;
  top:              20px;
  overflow:         auto;
  margin:           40px;
  width:            880px;
  padding:          10px 10px 17px 10px;
  background-color: #FFFFFF;
  border:           2px solid #788FDF;
}

@media screen and (max-height: 720px) {
  div.overlay:not(.volume_dialog):not(.filter) div.ovlwindow {
    bottom: 1%;
  }
}

@media screen and (max-height: 600px) {
  div.overlay.volume_dialog div.ovlwindow {
    bottom: 1%;
  }
}

@media screen and (max-height: 440px) {
  div.overlay.filter div.ovlwindow {
    bottom: 1%;
  }
}

div.cel-modal-overlay {
  position:         fixed;
  width:            100%;
  height:           100%;
  top:              0;
  left:             0;
  z-index:          999;
  display:          none;
  background-color: #AAAAAA;
  opacity:          0.6;
}

/**
 * Overview definition-lists
 */
dl.modoverview dt {
  background:   url(../gfx/icons/arrow_right.png) left center no-repeat;
  border-top:   1px dashed #CDCDCD;
  font-weight:  bold;
  margin-top:   5px;
  padding-top:  5px;
  padding-left: 22px;
}

dl.modoverview dt:first-child {
  border-top: 0;
}

/* ----------------------------------------------------------------------------------------
						jQuery / jQuery-UI
 ---------------------------------------------------------------------------------------- */

/**
 * Fix for header height using drop-down fields
 */
.ui-datepicker-header {
  height:        25px;
  margin-bottom: .5em;
}

/* ----------------------------------------------------------------------------------------
						WYSIWYG / TINY MCE
 ---------------------------------------------------------------------------------------- */
input.wysiwyg, textarea.wysiwyg {
  font-size:   12px;
  font-family: Helvetica, Arial, Nimbus Sans, sans-serif;
  font-weight: normal;
  display:     block;
  width:       98%;
  height:      400px;
  border:      none;
}

ul.tree, ul.tree ul {
  list-style-type: none;
  background:      url(../gfx/vline.png) repeat-y;
  margin:          0;
  padding:         0;
}

ul.tree ul {
  margin-left: 10px;
}

ul.tree li {
  margin:      0;
  padding:     0 12px;
  line-height: 20px;
  background:  url(../gfx/node.png) no-repeat;
}

ul.tree li:last-child {
  background: #FFFFFF url(../gfx/lastnode.png) no-repeat;
}

.cel-ajax-loader {
  margin:       0 auto;
  padding-left: 35px;
  padding-top:  22px;
  background:   transparent url(../gfx/ajax-loader.gif) no-repeat 2px 19px;
  height:       70px;
  width:        150px;
  display:      none;
}

/**
 * celanio actions drop-down
 * ************************************************************************** */

/* Cell for ui actions */
td.cel-ui-actions {
  width: 40px;
}

th.cel-ui-actions {
  width: 40px;
}

/* DropDown container */
.cel-ui-actions-dropdown {
  margin:   0 auto;
  position: relative;
}

/* DropDown trigger */
.cel-ui-actions-dropdown-trigger {
  display:         block;
  background:      transparent url(../gfx/icons/cel-action-dropdown.png) no-repeat 0 0;
  width:           48px;
  height:          24px;
  background-size: 44px;
}

/* DropDown container */
.cel-ui-actions-dropdown-container {
  display:            none;
  position:           absolute;
  background:         white;
  width:              200px;
  border:             1px solid #ddd;
  z-index:            20;
  top:                0;
  right:              40px;
  color:              black;
  border-radius:      4px;
  -webkit-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.4);
  -moz-box-shadow:    0px 0px 40px 0px rgba(0,0,0,0.4);
  box-shadow:         0px 0px 40px 0px rgba(0,0,0,0.4);
  padding:            5px;
}

.cel-ui-actions-dropdown-container.cel-ui-dropdown-open-right {
  right: auto;
  left:  40px;
}

.cel-ui-selected {
  display: block;
}

.cel-ui-trigger-selected {
  background-color: gray;
}

/* DropDown content */
.cel-ui-actions-dropdown-container > a {
  display:       block;
  height:        25px;
  padding:       5px 10px 5px 30px;
  background:    transparent no-repeat 3px center;
  color:         inherit;
  text-overflow: ellipsis;
  overflow:      hidden;
  white-space:   nowrap;
  font-weight:   normal !important;
  box-sizing:    content-box;
  font-size:     1em;
}

.cel-ui-actions-dropdown .cel-ui-divider {
  display:        block;
  border-bottom:  1px solid silver;
  padding-bottom: 5px;
  margin-bottom:  5px;
}

.cel-draggable {
  margin:      0 5px 5px 5px;
  height:      1.5em;
  line-height: 1.2em;
}