.overlay {
  position: absolute;
  top: 0;
  z-index: 10;
  max-height: 100%;
  overflow-y: scroll;
  .ovlwindow{
    padding: 10px 25px;
    background-color: #E8E8E8;
  }
}
/*Moodul 11905*/
#view_filter{
  padding-top: 10px;
  max-width: 650px;
  input.form-control[readonly]{
    background-color: #fff;
  }
}

.tbl_list.table{
  .fa{
    font-size: 18px;
  }
}